import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { folderState } from '../Recoil/atoms';
import { Pencil } from 'lucide-react';


const AffiliateLink = ({ blogView, followView, item, updateAffiliateLink, folder, index, postView, folderId }) => {
    const [showAffiliateInput, setShowAffiliateInput] = useState(false);
    const [affiliateLink, setAffiliateLink] = useState(!!item.affiliate_link ? item.affiliate_link : '');
    const [folders, setFolders] = useState(folderState);

    const handleSubmitAffiliateLink = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: "Trips",
            action: "User Added Affiliate Link to Itinerary Item"
        })
        updateAffiliateLink(index, affiliateLink, folderId);
        setShowAffiliateInput(false);
    };

    const turnLinkToString = (link) => {
        if (!!link) {
            console.log(typeof link);
            link.split('https://');
            console.log(link);
            const newLink = link.toString();
            return newLink;
        }
    }

    useEffect(() => {
        if (affiliateLink != item.affiliate_link) {
            setAffiliateLink(() => !!item.affiliate_link ? item.affiliate_link : '');
        }
    }, [item]);

    return (
        <>
            {!!blogView && !showAffiliateInput && (
                // <Row className='m-0 p-0 w-100 mw-100'>
                //     <Col className='mt-0 mb-0'>
                //         <Row className='border-top mt-0 mb-0'>
                //             <Col className='mt-2 pr-0 col-md-9 left-align d-inline'>
                //                 <h6 className='m-0 d-inline'>Book:</h6>
                //                 <p className='d-inline mb-0 mt-0 mr-1 ml-1'>{!!item.affiliate_link ? <a href={`${item.affiliate_link}`} target="_blank" rel="nofollow">{item.affiliate_link}</a> : 'Add an affiliate link.'}</p>
                //             </Col>
                //             <Col className='mt-1 col-md-3 right-align'>
                //                 <Button size="sm" className='btn-light' onClick={() => setShowAffiliateInput(true)}><Image src={EditIcon} style={{ height: "1em" }} /></Button>
                //             </Col>
                //         </Row>
                //     </Col>
                // </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className='col-md-10 d-inline left-align mt-3 affiliate-link'>
                                            <h6 className='m-0 d-inline'>Book:</h6>
                                            {/* {!!item.affiliate_link && (
                                                <p>{item.affiliate_link.toString()}</p>
                                            )} */}
                                            <p className='mb-0 mt-0 mr-1 ml-1 text-wrap d-inline' style={{ maxWidth: "50%" }}>{!!item.affiliate_link ? <a className='text-wrap' href={`${item.affiliate_link}`} target="_blank" rel="nofollow">{item.affiliate_link}</a> : 'Add an affiliate link. (optional)'}</p>
                                        </Col>
                                        <Col className='mt-1 col-md-2 right-align'>
                                            <Button size="sm" className='btn-transparent' onClick={() => setShowAffiliateInput(true)}><Pencil color='#424242' size={20} /></Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
            )}
            {!!blogView&& !!showAffiliateInput && (
                <Row className='mb-0'>
                    <Col className='mt-0 mb-0'>
                        <Form onSubmit={handleSubmitAffiliateLink}>
                            <Form.Group as={Row} className='text-left mt-0 mb-1'>
                                <Col className='mt-3 col-12 left-align d-inline pr-0 mr-0'>
                                    <Form.Label className='d-inline'><h6 className='m-0 d-inline'>Book:</h6></Form.Label>
                                    <Form.Control className='ml-1 d-inline' style={{ width: "60%" }} placeholder='Add an affiliate link.' value={affiliateLink} onChange={(e) => setAffiliateLink(e.target.value)} id='affiliateLinkInput' />
                                    <Button className='d-inline btn-primary ml-1 pr-2 pl-2 pt-1 pb-1 d-inline ml-0 mb-1' type="submit" size="md">Save</Button>
                                    <Button className='d-inline btn-secondary ml-1 pr-2 pl-2 pt-1 pb-1 ml-0 mb-1' onClick={() => setShowAffiliateInput(false)}>Cancel</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            )}
            {!!followView && item.affiliate_link && (
                <Row className='mb-0'>
                    <Col className='mt-0 mb-0'>
                        <Row className='mt-0 mb-0'>
                            <Col className='mt-0 pr-0 left-align d-inline affiliate-link'>
                                <h6 className='m-0 d-inline'>Book:</h6>
                                <p className='d-inline mb-0 mt-0 mr-1 ml-1'>{!!item.affiliate_link ? <a href={`${item.affiliate_link}`} target="_blank" rel="nofollow">{item.affiliate_link}</a> : 'Add an affiliate link.'}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    )
};

export default AffiliateLink;