import React, { useState } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { URL } from '../../config';
import { Trash } from 'lucide-react';
import { getFolders } from '../apiHelpers';
import axios from 'axios';
import { UserState, folderState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import UpdateBlogName from './UpdateBlogName';
import { useNavigate } from 'react-router-dom';
import RenameBlogModal from './RenameBlogModal';
import LikeButton from '../Feed/LikeButton';
import LikeDetails from '../Feed/LikeDetails';


const BlogCard = ({ folder, setCurrentFolder, blogView, currentFolder }) => {
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [currentTripNameEditable, setCurrentTripNameEditable] = useState(false);
    const [newLikeCount, setNewLikeCount] = useState(0);

    const navigate = useNavigate();

    const uploadTripBackground = async (formData) => {
        ReactGA.event({
            category: "Blogs",
            action: "User adds background picture to blog"
        })
        const res = await axios.post(`${URL}/users/folders/${folder.id}/background`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
        if (res.status === 200) {
            getFolders(user, setFolders)
        }
    };

    const deleteFolder = async (userid, folderid) => {
        ReactGA.event({
            category: "Blogs",
            action: "User deletes folder"
        })
        const url = `${URL}/users/folders`;
        const res = await axios.delete(url, {params: { userid, folderid, blog: true }});
        const body = await res.data;
        setFolders(body);
    };

    const updateTripName = async (folderId, folderName) => {
        const body = {
            folderName: folderName
        }

        const url = `${URL}/users/folders/${folderId}/name`;
        const res = await axios.put(url, body);
        return res;
    };

    const goToBlogView = () => {
        navigate(`/album/${folder.id}`);
    };

    const getBackgroundImage = () => {
        if (!!folder.background_image) {
            return `url(${URL}/${folder.background_image.filename})`
        } else if (!!folder.folder_background_image) {
            return `${URL}/${folder.folder_background_ima}`
        }
    }

    const navigateToUserProfile = () => {
        ReactGA.event({
            category: 'Feed',
            action: 'User views another users profile from feed'
        })
        navigate(`/${folder.username}`);
    }


    return (
        <Col className='mt-3 border-bottom border-black-50 shadow'>
            {!!folder.profile_image && (
                <Row className='mb-1'>
                    <Col className='left-align mb-0 pb-0 col-md-2 col-2 pr-0 mt-2'>
                        <div className='feed-profile-pic mr-0 pr-0' onClick={navigateToUserProfile}>
                            <Image src={`${URL}/${folder.profile_image.path}`} className='mw-100 full-center' loading='lazy' />
                        </div>
                        {/* <Image className='mb-0 mr-0 ml-0 mt-1 p-0 d-inline feed-profile-pic' src={`${URL}/${profilePic.filename}`} onClick={navigateToUserProfile} /> */}
                    </Col>
                    <Col className={`left-align mb-0 pb-0 mt-2 ${!!folder.profile_image ? 'pl-0' : 'pl-3'}`} style={{ marginLeft: "-0.3rem" }}>
                        <p className='font-weight-bold mb-0 pb-0 text-primary' onClick={navigateToUserProfile} style={{ fontSize: "1rem", cursor: "pointer", marginTop: "-0.3rem" }}>{folder.username}</p>
                        <p className="ml-0 mb-0 geo-text" style={{ fontSize: "0.8rem", marginTop: "-0.25rem" }}>{folder.location}</p>
                    </Col>
                </Row>
            )}
            <Row className='trip-cover-img-bg cursor-pointer' onClick={() => goToBlogView()} style={{ backgroundColor: "dimgray" }}>
                <Image src={!!folder.background_image && `${URL}/${folder.background_image.path}`} className='full-center w-100' loading='lazy' />
            </Row>
            <Row>
                <Col className='col-9 mb-2 text-left' onClick={() => goToBlogView()}>
                    <h5 className='text-primary pt-2 mt-1 mb-0 pb-1 cursor-pointer'>{folder.name}</h5>
                    <LikeDetails userLikes={folder.user_likes} newLikeCount={newLikeCount} />
                    {!folder.user_likes && (
                            <Row className='pr-3 pl-0 mb-0 pb-0 mr-0 ml-0 mw-100 mobile-hide'>
                                <Col className='left-align pl-0 mb-0 pb-0'>
                                    <p className='text-white mb-0 pb-0'>No likes yet</p>
                                </Col>
                            </Row>
                    )}
                </Col>
                <Col className='col-3 text-right'>
                    {!!blogView ? (
                        <>
                            <Button className='btn-transparent pr-0 pl-1' onClick={() => deleteFolder(user.id, folder.id)}>
                                <Trash color='#424242' size={25} />
                            </Button>
                            <div className='ml-1 d-inline'>
                                <LikeButton
                                    userLikes={folder.user_likes}
                                    setNewLikeCount={setNewLikeCount}
                                    user={user}
                                    folderLike={true}
                                    folderId={folder.id}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <LikeButton
                                userLikes={folder.user_likes}
                                setNewLikeCount={setNewLikeCount}
                                user={user}
                                folderLike={true}
                                folderId={folder.id}
                            />
                        </>
                    )}
                </Col>
            </Row>
            {!!blogView && (
                <Row>
                    <Col>
                        <UpdateBlogName currentTripNameEditable={currentTripNameEditable} setCurrentTripNameEditable={setCurrentTripNameEditable} updateTripName={updateTripName} folder={folder} currentFolder={currentFolder} />
                    </Col>
                </Row>
            )}
    </Col>
    );
};

export default BlogCard;