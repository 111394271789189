import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { folderState, UserState } from "../Recoil/atoms";
import { useRecoilState } from "recoil";
import axios from 'axios';
import { URL } from "../../config";
import { getFolders } from "../apiHelpers";
import { Bookmark, Move } from "lucide-react";

const BookmarkButton = ({ item, bookMarkSize, wishList, ownProfile, location, addAble, folder, searchView }) => {
    const [showModal, setShowModal] = useState(false);
    const [showTripModal, setShowTripModal] = useState(false);
    const [newFolder, setNewFolder] = useState('');
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [showItemAlert, setShowItemAlert] = useState(false);
    const [showFolderAlert, setShowFolderAlert] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [createFolderError, setCreateFolderError] = useState(false);
    const [showBlogModal, setShowBlogModal] = useState(false);
    const [bookMarked, setBookMarked] = useState(false);
    const [showDreamModal, setShowDreamModal] = useState(false);


    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const toggleCreateTripModal = () => setShowTripModal((showTripModal) => !showTripModal);
    const toggleCreateBlogModal = () => setShowBlogModal(showBlogModal => !showBlogModal);
    const toggleCreateDreamModal = () => setShowDreamModal(showDreamModal => !showDreamModal);

    const findWishlist = () => {
        let wishList;
        for (let i = 0; i < folders.length; i++) {
            if (folders[i].name === "Wishlist") {
                wishList = folders[i]
            }
        }
        return wishList;
    }

    const wishListFolder = findWishlist() || {};

    const handleSelect = async (folderId, folderName) => {
        // console.log('folderid: ', folderId)
        // console.log('Adding item: ', item);
        // console.log('folder: ', folderId);
        // console.log('folder name: ', folderName);
        switch(location) {
            case 'Feed':
                ReactGA.event({
                    category: "Feed",
                    action: "User Bookmarks Item in Feed"
                })
            case 'Search':

            case 'Follow':
                ReactGA.event({
                    category: 'Follow',
                    action: 'User Bookmarks Item From Another Users Folders'
                })
            case 'Wishlist':
                ReactGA.event({
                    category: 'Trips',
                    action: 'User Moves Item from Wishlist to Trip'
                })

        }
        setBookMarked(true);
        setSelectedFolder(folderName);
        const place = {
            scheduled_time: null,
            place_id: item.place_id,
            name: item.name,
            rating: item.rating,
            plus_code: item.plus_code,
            formatted_address: item.formatted_address,
            photos: item.photos,
            day: null,
            user_photos: item.user_photos,
            notes: item.notes,
            affiliate_link: item.affiliate_link
        }

        const body = {
            place: place,
            folderName: folderName,
            userId: userInfo.id,
            username: userInfo.username,
            profilePic: userInfo.profile_image
        }

        if (folderId === "Wishlist") {
            for (let i = 0; i < folders.length; i++) {
                if (folders[i].name === "Wishlist") {
                    folderId = folders[i].id;
                }
            }
        }
        return axios.post(`${URL}/users/folders/${folderId}`, body)
            .then(res => {
                setShowItemAlert(true);
                setTimeout(() => setShowItemAlert(false), 1500);
                setTimeout(() => setSelectedFolder(''), 1500);
                // console.log('successfully added item: ', res);
                setShowBlogModal(false);
                setShowDreamModal(false);
                setShowTripModal(false);
                return res;
            })
            .then(() => {
                return getFolders(userInfo, setFolders)
            })
            .catch(err => console.log(err))

    };



    const isBookMarked = (currentItem) => {
        // console.log('current item id: ', currentItem);
        // console.log('own profile? ', ownProfile);
        // if (!!ownProfile) {
        //     return false;
        // }
        for (let i = 0; i < folders.length; i++) {
            const contents = folders[i].contents
            // console.log('folder contents: ', contents);
            if (!!contents) {
                for (let j = 0; j < contents.length; j++) {
                    // console.log('folder item: ', contents[j]);
                    if (contents[j].place_id === currentItem.place_id) {
                        setBookMarked(true);
                        return true;
                    }
                }
            }
        }
        setBookMarked(false);
        return false;
    };

    useEffect(() => {
        isBookMarked(item);
    }, [folders, item])

    const folderContainsItem = (folder, currentItem) => {
        if (!!showModal || !!searchView) {
            const contents = !!folder.contents ? folder.contents : [];
            for (let i = 0; i < contents.length; i++) {
                if (contents[i].place_id === currentItem.place_id) {
                    return true;
                }
            }
            return false;
        }
    }

    const createFolder = async (blogView, dreamView, e) => {
        e.preventDefault();

        if (blogView === true ) {
            ReactGA.event({
                category: "Share",
                action: "User Creates New Blog"
            })
        } else if (dreamView === true) {
            ReactGA.event({
                category: "Dreams",
                action: "User Creates New Dream"
            })
        } else {
            ReactGA.event({
                category: "Plan",
                action: "User Creates New Itinerary"
            })
        }



        if (newFolder.length < 1) {
            setCreateFolderError(true);
            return;
        } else {
            setCreateFolderError(false);
        }
        let req = {
            folder: newFolder,
            username: userInfo.username,
            blogView: blogView,
            dreamView: dreamView
        }
        
        if (!!userInfo) {
            const res = await axios.post(`${URL}/users/${userInfo.id}/folders`, req)
            setNewFolder('');
            if (res.status === 201) {
                getFolders(userInfo, setFolders);
                setShowBlogModal(false);
                setShowTripModal(false);
            } else {
                setError(true);
            }

        }
    }
    
    return (
        <>
            {(!addAble && (!!folder && !folder.blog_view)) ? 
                <Button variant="primary" size="md" className="p-0 btn-transparent d-inline ml-2 mt-1" onClick={handleShow}>
                    {/* <Image className='pl-0 ml-0 mr-0 filter-primary' style={{ height: bookMarkSize }} src={MoveToIcon} />  */}
                    <Move color="#424242" size={30} />
                </Button>
                : !!searchView && !!folder ? null
                : !!ownProfile ? null
                : !!bookMarked ? 
                <Button variant="primary" size="md" className="p-0 btn-transparent d-inline ml-2 mt-1" onClick={handleShow}>
                    {/* <Image className='pl-0 ml-0 mr-0 filter-primary' style={{ height: bookMarkSize }} src={BookMarkIconFilled} />  */}
                    <Bookmark color="#15084D" fill="#15084D" size={30} />
                </Button>
                :
                <Button variant="primary" size="md" className="p-0 btn-transparent d-inline ml-2 mt-1" onClick={handleShow}>
                    {/* <Image className='pl-0 ml-0 mr-0 filter-primary' style={{ height: bookMarkSize }} src={BookmarkIcon} />  */}
                    <Bookmark color="#424242" size={30} />
                </Button>
            }
            {!!searchView && !!folder && folderContainsItem(folder, item) && (
                    <Button size="md" className="btn-light mt-1" disabled>Selected</Button>
            )}
            {!!searchView && !!folder && !folderContainsItem(folder, item) && (
                <Button size="md" 
                    className="btn-secondary mt-1"
                    onClick={(e) => handleSelect(folder.id, folder.name, folder.blog_view)}
                >Select</Button>
            )}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header className="bg-light" closeButton>
                    {!!folder ? (
                        <Modal.Title>Move To:</Modal.Title>
                    ) : (
                        <Modal.Title>Bookmarks</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                <Row>
                        <Col>
                            <h4>Dream</h4>
                        </Col>
                    </Row>
                    {!wishList && (
                        <Row className="border-top border-bottom">
                            <Col className="col-8">
                                <p className="mt-2 mb-2" onClick={(e) => handleSelect("Wishlist", "Wishlist")}>Wishlist</p>
                            </Col>
                            <Col className="col-4 right-align">
                                {folderContainsItem(wishListFolder, item) ? (
                                    <Button size="sm" className="btn-light mt-1" disabled>Bookmarked</Button>
                                ) : (
                                    <Button size="sm" 
                                        className="btn-secondary mt-1"
                                        onClick={(e) => handleSelect("Wishlist", "Wishlist")}
                                    >Select</Button>
                                )}
                            </Col>
                        </Row>
                    )}
                    {folders.map((folder, i) => {
                        if (folder.name != "Wishlist" && folder.dream_view === true) {
                            return (
                                <Row key={i}>
                                    <Col className="col-8">
                                    <p  className="mt-2 mb-2"
                                        onClick={(e) => handleSelect(folder.id, folder.name)}>
                                            {folder.name}
                                    </p>
                                    </Col>
                                    <Col className="col-4 right-align">
                                        {folderContainsItem(folder, item) ? (
                                            <Button size="sm" className="btn-light mt-1" disabled>Bookmarked</Button>
                                        ) : (
                                            <Button size="sm" 
                                                className="btn-secondary mt-1"
                                                onClick={(e) => handleSelect(folder.id, folder.name, folder.blog_view)}
                                            >Select</Button>
                                        )}
                                    </Col>
                                </Row>
                            )
                        }
                    })}
                    <Row>
                        <Col className="text-center">
                            <Button size="sm" onClick={toggleCreateDreamModal} className="rounded-pill mt-2 mb-3" >Create New Inspiration</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Plan:</h4>
                        </Col>
                    </Row>
                    {folders.map((folder, i) => {
                        if (folder.name != "Wishlist" && !folder.blog_view && !folder.dream_view) {
                            return (
                                <Row key={i} className="border-top">
                                    <Col className="col-8">
                                    <p  className="mt-2 mb-2"
                                        onClick={(e) => handleSelect(folder.id, folder.name, folder.blog_view)}>
                                            {folder.name}
                                    </p>
                                    </Col>
                                    <Col className="col-4 right-align">
                                        {folderContainsItem(folder, item) ? (
                                            <Button size="sm" className="btn-light mt-1" disabled>Bookmarked</Button>
                                        ) : (
                                            <Button size="sm" 
                                                className="btn-secondary mt-1"
                                                onClick={(e) => handleSelect(folder.id, folder.name, folder.blog_view)}
                                            >Select</Button>
                                        )}
                                    </Col>
                                </Row>
                            )
                        }
                    })}
                    <Row>
                        <Col className="text-center">
                            <Button size="sm" onClick={toggleCreateTripModal} className="rounded-pill mt-2 mb-3" >Create New Itinerary</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Share:</h4>
                        </Col>
                    </Row>
                    {folders.map((folder, i) => {
                        if (folder.blog_view === true) {
                            return (
                                <Row key={i} className="border-top">
                                <Col className="col-8">
                                <p className="mb-2 mt-2"
                                    onClick={(e) => handleSelect(folder.id, folder.name, folder.blog_view)}>
                                        {folder.name}
                                </p>
                                </Col>
                                <Col className="col-4 right-align">
                                    {folderContainsItem(folder, item) ? (
                                        <Button size="sm" className="btn-light mt-1" disabled>Bookmarked</Button>
                                        ) : (
                                        <Button size="sm" 
                                            className="btn-secondary mt-1"
                                            onClick={(e) => handleSelect(folder.id, folder.name, folder.blog_view)}
                                        >Select</Button>
                                    )}
                                </Col>
                            </Row>
                            )
                        }
                    })}
                    <Row className="border-top">
                        <Col className="text-center">
                            <Button size="sm" onClick={toggleCreateBlogModal} className="rounded-pill mt-2 mb-3">Create New Album</Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTripModal} onHide={toggleCreateTripModal}>
            <Modal.Header closeButton>
                <Modal.Title>Create A New Itinerary</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pr-0 pl-0">
                <Form onSubmit={(e) => createFolder(false, false, e)}>
                    <Form.Control size="md" className="bg-light border-0" placeholder="Name Your Itinerary!" value={newFolder} onChange={e => setNewFolder(e.target.value)} />
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                    <Button variant="secondary" onClick={toggleCreateTripModal}>
                        Close
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
        <Modal show={showBlogModal} onHide={toggleCreateBlogModal}>
            <Modal.Header closeButton>
                <Modal.Title>Create A New Album</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => createFolder(true, false, e)}>
                    <Form.Control size="md" className="bg-light border-0" placeholder="Name Your Album!" value={newFolder} onChange={e => setNewFolder(e.target.value)} />
                    <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                    <Button variant="secondary" onClick={toggleCreateBlogModal}>
                        Close
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
        <Modal show={showDreamModal} onHide={toggleCreateDreamModal}>
            <Modal.Header closeButton>
                <Modal.Title>Create A New Inspiration Folder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => createFolder(false, true, e)}>
                    <Form.Control size="md" className="bg-light border-0" placeholder="Name Your Folder!" value={newFolder} onChange={e => setNewFolder(e.target.value)} />
                    <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                    <Button variant="secondary" onClick={toggleCreateDreamModal}>
                        Close
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default BookmarkButton;