import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Col, Container, Row, Form, Button, Image, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight, ImagePlus } from 'lucide-react';
import SearchModal from '../DiscoverView/SearchModal';
import Rating from '../Experiences/Rating';
import { URL as API_URL } from '../../config';
import axios from 'axios';
import ReactGA from 'react-ga4';
import Details from '../Experiences/Details';
import LoadingGif from '../../images/loading.gif';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

const NewPost = () => {
    const inputRef = useRef(null);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [files, setFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [isVideo,setIsVideo] = useState(false);
    const [place, setPlace] = useState('');
    const [notes, setNotes] = useState('');
    const [affiliateLink, setAffiliateLink] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useRecoilState(UserState);

    const { blogid, name } = useParams();

    const navigate = useNavigate();

    const onDrop = useCallback((acceptedFiles) => {
        let updatedFiles = [...files, ...acceptedFiles];
        setFiles(updatedFiles);
    }, [files]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const setImage = (e) => {
        setFiles(inputRef.current.files);
        const fileType = inputRef.current.files[photoIndex].name.split('.')[inputRef.current.files[photoIndex].name.split('.').length - 1];
        if (fileType == 'mov' || fileType == 'mp4') {
            // console.log('is movie');
            // setVideoUrl(`${URL}/${item.user_photos[photoIndex].filename}`);
            setCurrentFile(URL.createObjectURL(inputRef.current.files[photoIndex]));
            setIsVideo(true);
        } else {
            setIsVideo(false);
            setCurrentFile(URL.createObjectURL(inputRef.current.files[photoIndex]));
        }
    };

    const discardChanges = () => {
        setFiles([]);
        setCurrentFile(null);
        setPlace('');
        setNotes('');
        setAffiliateLink('');
        setPhotoIndex(0);
    };

    const createPost = async (e) => {
        setLoading(true);
        e.preventDefault();
        ReactGA.event({
            category: "Blogs",
            action: "New Blog Post"
        });
        if (!place) {
            setError(true)
        } else {
            place.affiliate_link = affiliateLink;
            place.notes = notes;
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i])
            }
            formData.append('place', JSON.stringify(place));
            try {
                const res = await axios.post(`${API_URL}/blogs/${blogid}/user/${user.id}/post`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
                if (res.status === 201) {
                    setLoading(false);
                    setSuccess(true);
                    setTimeout(() => navigate(`/album/${blogid}`), 1500);
                    setTimeout(() => setSuccess(false), 1400);
                }
            } catch (err) {
                setError(true);
                setLoading(false);
            }
        }
    }


    useEffect(() => {
        if (!!files && files.length > 0) {
            const fileType = files[photoIndex].name.split('.')[files[photoIndex].name.split('.').length - 1];
            if (fileType == 'mov' || fileType == 'mp4' || fileType == 'MOV') {
                // setVideoUrl(`${URL}/${item.user_photos[photoIndex].filename}`);
                setIsVideo(true);
                setCurrentFile(URL.createObjectURL(files[photoIndex]));
            } else {
                setIsVideo(false);
                setCurrentFile(URL.createObjectURL(files[photoIndex]));
            }
        }
    }, [photoIndex, files]);

    const formatLocStr = (item) => {
        let strArr = [''];
        // console.log('item plus code: ', item.plus_code);
        if (!!item.plus_code && item.plus_code !== undefined) {
            if (!!item.plus_code.compound_code && item.plus_code.compound_code.includes(' ')) {
                strArr = item.plus_code ? item.plus_code.compound_code.split(' ') : [''];
            }
        } 
        let newLocStr = '';
        // console.log('Loc Str Arr: ', strArr);
        for (let i = 1; i < strArr.length; i++) {
            newLocStr += strArr[i]
            if (i < strArr.length -1) {
                newLocStr += ' ';
            }

        }
        // console.log('new location string: ', newLocStr);
        if (!!newLocStr && newLocStr.includes(', ')) {
            let newLocArr = newLocStr.split(', ');
            // console.log('new location arr: ', newLocArr);
            newLocStr = newLocArr[newLocArr.length - 2] + ', ' + newLocArr[newLocArr.length -1];
        }
        // console.log('new location string: ', newLocStr);
        // console.log('item formatted address: ', item.formatted_address);
        if (!newLocStr) {
            if (item.formatted_address.includes(', ')) {
                let strArr = item.formatted_address.split(', ');
                if (!!strArr[strArr.length -3]) {
                    newLocStr += strArr[strArr.length -3];
                    newLocStr += ', ' 
                }
                let cityArr;
                if (strArr[strArr.length -2].includes(' ') && !!strArr[strArr.length - 2]) {
                    cityArr = strArr[strArr.length -2].split(' ');
                } else {
                    cityArr = [strArr[strArr.length -2]];
                }
                // console.log('city array: ', cityArr);
                for (let i = 0; i < cityArr.length; i++) {
                    if (!Number(cityArr[i])) {
                        newLocStr += cityArr[i];
                        if (i < cityArr.length -1 && !Number(cityArr[i+1])) {
                            newLocStr += ' ';
                        }
                    }
                }
                newLocStr += ', ';
                newLocStr += strArr[strArr.length -1];
            } else {
                newLocStr = item.formatted_address;
            }
        }
        // console.log('final new location string: ', newLocStr);
        return newLocStr;
    };

    const styles = {
        dropzone: {
            width: '95%',
            height: '95%',
            border: '2px dashed white',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '10px',
            margin: 'auto',
            cursor: 'pointer', // Ensures it looks clickable
            zIndex: "2"
          },
      };

    return (
        <Container>
            <Row>
                <Col>
                    <h3 className='mb-0 mt-1'>New Post</h3>
                    <h5 className='text-dark'><span className='text-primary'>Album: </span>{name}</h5>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-4 offset-md-4 pr-0 pl-0 pb-2 pt-2 mt-1 mb-2 border-bottom border-black-50 shadow position-relative">
                    {!!loading && (
                        <div className='full-center w-75' style={{ zIndex: "3" }}>
                            <Image src={LoadingGif} className='w-100' />
                        </div>
                    )}
                    <Row className='feed-img-container mr-0 ml-0 pl-0 pr-0 mb-1 mt-1 position-relative' style={{ backgroundColor: "dimgray" }}>
                    {/* {files.length === 0 && (
                        <div className='full-center' style={{ zIndex: "2" }}>
                            <input
                                ref={inputRef}
                                onChange={setImage}
                                className='d-none'
                                type='file'
                                id='files'
                                name='files'
                                accept='*.MOV,video/mp4,video/x-m4v,video/*,image/*'
                                multiple
                            />
                            <Button className='btn-transparent' onClick={handleUpload}><ImagePlus size={100} color='white' /></Button>
                            <p className='text-white'>Add Photos/Videos</p>
                        </div>
                    )} */}
                    <div className='full-center' {...getRootProps()} style={styles.dropzone}>
                        <input {...getInputProps()} 
                            accept='video/*,image/*'
                            multiple
                        />
                        {isDragActive ? (
                            <p className='text-white full-center' style={{ fontSize: "1.5rem", textShadow: files.length > 0 && "2px 2px 4px rgba(0, 0, 0, 0.9)"}}>Drop the files here...</p>
                        ) : (
                            <div className='full-center w-100 pr-3 pl-3'>
                                <ImagePlus size={100} color='white' style={{ filter: files.length > 0 && "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))"}} />
                                <p className='text-white' style={{ fontSize: "1.5rem", textShadow: files.length > 0 && "2px 2px 4px rgba(0, 0, 0, 0.9)"}}>1) Drag 'n' drop some photos/videos here, or click to select files</p>
                            </div>
                        )}
                    </div>
                    {!isVideo && files.length > 0 && <Image src={currentFile} className="feed-img pl-0 pr-0 mr-0 ml-0" />}
                    {!!isVideo && files.length > 0 && (
                            <video key={currentFile} className='feed-img' autoPlay muted playsInline>
                                <source src={currentFile} type="video/mp4" />
                                {/* <source src={getCurrentPhoto(photoIndex)} type="video/ogg" />
                                <source src={getCurrentPhoto(photoIndex)} type="video/webm" /> */}
                                Your browser does not support the video tag.
                            </video>
                    )}
                    {photoIndex < files.length -1 && (
                            <ChevronRight
                                onClick={() => {
                                    setPhotoIndex(index => index + 1);
                                }}
                                size={40}
                                color='white'
                                className='right-arrow mr-2'
                                style={{ zIndex: "3" }}
                            />
                    )}
                    {photoIndex > 0 && (
                            <ChevronLeft
                                onClick={() => {
                                    setPhotoIndex(index => index - 1)
                                }}
                                size={40}
                                color='white'
                                className='left-arrow ml-2'
                                style={{ zIndex: "3"}}
                            />
                    )}
                    </Row>
                    <Row className='m-0'>
                        <Col>
                            {!place ? (
                                <div className='mb-2'>
                                    <p className='mb-0'>2) Tag a location. Be as specific as possible so your friends know where you went!</p>
                                    <SearchModal setPlace={setPlace} postView={true} iconSize={50} />
                                </div>
                            ) : (
                                // <div className='text-left'>
                                //     <p className='text-primary font-weight-bold mb-0 pb-0'>{place.name}</p>
                                //     <div className='p-0 mb-0 mr-0 ml-0' style={{ marginTop: "-0.5em"}}>
                                //         <Rating width={place.rating / 5 * 102.9} /><p className="geo-text d-inline mb-0 pb-0 ml-1">{place.rating}</p>
                                //     </div>
                                //     <p className="geo-text mb-0">{formatLocStr(place)}</p>
                                // </div>
                                <div className='text-left'>
                                    <Details place={place} postView={false} />
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className='m-0'>
                        <Col>
                            <Form.Group className='mb-0'>
                                <Form.Control as="textarea" rows={2} placeholder='3) Add a caption, tip or review. (optional)' value={notes} onChange={e => setNotes(e.target.value)} id='notesInput' />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='m-0'>
                        <Col>
                            <Form.Group as={Row} className='text-left mt-0 mb-1'>
                                <Col className='mt-1 col-12 left-align'>
                                    <Form.Control className='ml-0' placeholder='4) Add an affiliate link. (optional)' value={affiliateLink} onChange={(e) => setAffiliateLink(e.target.value)} id='affiliateLinkInput' />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                </Row>
                {!!error && (
                    <Row className='mt-3 text-center'>
                        <Col>
                            <Alert variant='danger'>There was an error loading your post. Make sure to include the location and keep files smaller than 20MB.</Alert>
                        </Col>
                    </Row>
                )}
                {!success && (
                    <>
                        <Row>
                            <Col>
                                <Button className='btn-primary rounded-pill mt-2' onClick={createPost}>Create</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className='btn-light rounded-pill mt-5 mb-3' onClick={() => discardChanges()}>Discard Changes</Button>
                            </Col>
                        </Row>
                    </>
                )}
                {!!success && (
                    <Row className='mt-3 text-center'>
                        <Col>
                            <Alert variant='success'>Memory Created!</Alert>
                        </Col>
                    </Row>
                )}
        </Container>
    );
};


export default NewPost;